body{
    padding: 0 !important;
}
.ant-modal{
    width: calc(73.2vw + 25.3px) !important;
flex-shrink: 0;
top: 100px;
}
.ant-modal-content{
    background: none !important;
    padding: 0 !important;
}
.ant-modal-footer{
    display: none;
}
.form_img {
    width: 100%;
}
.form_left{
    background: rgba(7, 28, 53, 0.75);
    backdrop-filter: blur(7.5px);
    width: 50%;
    top: 0;
    height: 100%;
}

.lineform_title{
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-family: 'Lato-Regular';
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 33px;   
    /* margin-left: 37px;  */
}

.form_secondline{
    display: flex;
    align-items: center;
}
.iconforms{
    margin-right: 30px;
}
.form_left{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.form_right{
    top: 0;
    right: 0;
    width: 50%;
    fill: rgba(7, 28, 53, 0.75);
    filter: drop-shadow(0px 20px 40px rgba(0, 0, 0, 0.25));
    backdrop-filter: blur(7.5px);
    background: rgba(7, 28, 53, 0.432);
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.downloar_bro{
    color: #FFF;
    text-align: center;
    font-size: 30px;
    font-family: 'Alethiapro-Regular';
    line-height: 40px; /* 133.333% */
    text-transform: uppercase;   
    width: 350px;
}
.form_all{
    background-image: url("../images/bg.png") !important;
    width: 100%;
    fill: rgba(7, 28, 53, 0.75);
    filter: drop-shadow(0px 20px 40px rgba(0, 0, 0, 0.25));
    backdrop-filter: blur(7.5px);
}

.myform{
    width: 100%;
    position: relative;
    height: 50px;
    overflow: hidden;
    /* background: blue; */
    margin-left: 75px;
    margin-bottom: 30px;
}

.myform input {
    height: 100%;
    padding-top: 20px;
    border: none;
    outline: none;
    font-size: 14px;
    background: none;
}

.myform label {
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 100%;
    width: 350px;
    /* background: blue; */
    pointer-events: none;   /* So you can click through the label */
    border-bottom: 1px solid rgba(255, 255, 255, 0.10);
    
}

.myform label::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    height: 100%;
    width: 100%;
    border-bottom: 3px solid #FFF;;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
}

.form_name {
    position: absolute;
    bottom: 12px;
    left: 0px;
    transition: all 0.3s ease;
    color: #FFF;
    font-size: 16px;
    font-family: 'Lato-Light';
    line-height: normal;
}

.myform input:focus + .label-name .form_name,
.myform input:valid + .label-name .form_name {
    transform: translateY(-150%);
    font-size: 14px;
    color: #FFF;
    bottom: 5px;;
}

.myform input:focus + .label-name::after,
.myform input:valid{
    transform: translateX(0%);
}
.brochure_link{
    background: #FFF;
    padding: 11px 92px ;
    color: #071C35 !important;
    text-align: center;
    font-size: 14px;
    font-family: 'Lato-Regular';
    text-transform: uppercase; 
    text-decoration: none;   
}
.ant-modal-close-x{
    color: #FFF;
    font-size: 22px !important;
}
@media (max-width: 935px){
    .form_all{
        flex-direction: column !important;
    }
}