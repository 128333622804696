p{
    margin-bottom: 0;
}
.footer_top-title{
    color: #071C35;
    font-size: calc(1.03vw + 29.1px);
    font-style: normal;
    font-family: 'Alethiapro-Regular';
    line-height: 40px; /* 90.909% */
    text-transform: uppercase;    
    margin-bottom: 7px;
}
.footer_top{
    margin-top: calc(-18.7vw + 520.4px);
    width: 64.5%;
    margin-right: 70px;
}
.footer_top-hr{
    /* opacity: 0.2; */
    background: #D9D9D9;
    width: calc(55.2vw + 72.9px);
    height: 18px !important;
    margin-bottom: 37px;
}
.footer_topf-p{
    color: #071C35;
    font-size: calc(0.1vw + 13.2px);
    line-height: 24px; /* 150% */
    font-family: 'Lato-Medium';   
    margin-left: calc(0.5vw - 41px); 
}
.footer_top-text{
    color: #777;
    font-family: 'Lato-Light';
    font-size: calc(0.1vw + 13.2px);
    line-height: 24px; /* 150% */    
    width: calc(28.5vw + 22.9px);
    margin-left: calc(2.8vw - 5.5px);
}
.footer_top-flex{
    display: flex;
    align-items: center;
    margin-bottom: calc(3vw + 13.7px);
}
.footer_all{
    display: flex;
    justify-content: end;
}
.footer_toprel {
    display: flex;
    align-items: center;
    margin-left: 10px;
}
.footer_top-form{
    margin-bottom: 50px;
}
.footer_bottom{
    background: rgba(7, 28, 53, 1);
    height: calc(-11vw + 396.5px);
    display: flex;
    justify-content: space-between;
}
.imagewom{
    bottom: 0;
    margin-left: calc(5.6vw - 11px);
    /* display: none; */
    width: calc(11.5vw + 146.6px);
}
.footer_line-p{
    color: #FFF;
    font-size: 14px;
    line-height: 24px; /* 171.429% */
    text-transform: uppercase; 
    font-family: 'Lato-Regular';  
    margin: calc(-2vw + 29.7px) calc(1.7vw + 4.3px) calc(0.7vw + 19px) calc(1.7vw + 4.3px); 
}
.footer_line-text{
    color: #FFF;
    font-family: 'Lato-Regular';  
    font-size: calc(0.3vw + 14.5px);
    line-height: normal;
    margin-right: 10px;
}
.footer_secondline-text{
    color: #C3C3C3;
    font-family: 'Lato-Regular';  
    font-size: calc(0.1vw + 13.2px);
    line-height: normal; 
    
}
.teleg_icon{
    margin-left: 10px;   
}
.show{
    color: #C3C3C3;
    margin-right: calc(2.3vw + 16.1px);
}

.footer_line,.footer_bottomright{
    display: flex;
    justify-content: start;
}
.footer_secondline,  .footer_bottomright{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.tele{
    margin-right: 30px;
}
.bottom_icon{
    margin: calc(3.1vw + 43px) calc(0.9vw + 35.4px);
}











.form {
    width: 50%;
    position: relative;
    height: 50px;
    overflow: hidden;
    /* background: blue; */
}

.form input {
    width: 100%;
    height: 100%;
    padding-top: 20px;
    border: none;
    outline: none;
    font-size: 14px;
    background: none;
}

.form label {
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 100%;
    width: 200px;
    /* background: blue; */
    pointer-events: none;   /* So you can click through the label */
    border-bottom: 1px solid rgba(7, 28, 53, 0.10);
}

.form label::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    height: 100%;
    width: 100%;
    border-bottom: 3px solid #071C35;;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
}

.content-name {
    position: absolute;
    bottom: 12px;
    left: 0px;
    transition: all 0.3s ease;
    color: #071C35;
    font-size: calc(0.1vw + 13.2px);
    font-family: 'Lato-Light';
    line-height: normal;
}

.form input:focus + .label-name .content-name,
.form input:valid + .label-name .content-name {
    transform: translateY(-150%);
    font-size: 14px;
    color: #071C35;
    bottom: 5px;;
}

.form input:focus + .label-name::after,
.form input:valid{
    transform: translateX(0%);
}


.form_link{
    color: #071C35;
    font-family: 'Lato-Regular';
    font-size: 14px;
    font-weight: 400;
    border: 1px solid rgba(7, 28, 53, 0.15);
    padding: 11px 44px;
    width: 201px;
    margin-bottom: 0px;
    text-transform: uppercase;
}
.footer_top-form{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@media (max-width: 1025px){
    .footer_top-form {
        flex-direction: column;
    }
    .form_link{
        margin-top: 15px;
    }
}
@media (max-width: 1247px){
    .footer_bottomright {
        flex-direction: column;
        align-items: center;
        justify-content: start;
    }

}
@media (max-width: 710px){
    .imagewom{
        display: none;
    }
    .footer_bottom{
        justify-content: center;
    }
}
@media (max-width: 414px){
    .footer_secondline{
        flex-direction: column;
        align-items: center;
    }
    .footer_line-text, .footer_secondline-text{
        margin-top: 10px;
    }
    .tele{
        margin-right: 0;
    }
}